import GraphicsBanner from '../images/printHolder.png';
import MobileGraphicsBanner from '../images/MobileGraphicsBanner.png'
import SmallBrochure from '../images/print_thumbs/smallBrochure.jpg';
import IllustrationLandscape from '../images/print_thumbs/illustration_landscape.jpg';
import SmCD from '../images/print_thumbs/smallCd.png';
import EarthAlien from '../images/print_thumbs/illustration-earthalien.jpg';
import MetalCard from '../images/print_thumbs/metal-card.png';
import PrintHead from '../images/print-head.png';
import { Helmet } from 'react-helmet-async';

const GraphicDesign = () => {
    return (
        <div className="sub">
            <Helmet>
                <title>Graphic Design Listings- B.Crowe Design</title>
                <meta name="Graphic Design Listings- B.Crowe Design" content="BCrowe.net- Graphic Design  Listings" />
            </Helmet>
            <div class="top-Z-Sub-tile"></div>
            <div class="mid-Z-Sub-tile"></div>
            <div class="bot-Z-Sub-tile"></div>
            <div class="bannerHolder graphics">
                {/* <img src={GraphicsBanner} alt="graphics, illustration, and more" /> */}
                <img src={MobileGraphicsBanner} className='d-md-none d-lg-none img-fluid' />
            </div>
            <div id="mainWrapper">
                <div id="copyFrame" class="sub">
                    <div className="copyTop">
                        <div className="subcopyTopLC"></div>
                        <div className="subcopyTopTile"><div className="subcopyTopRC"></div></div>
                    </div>
                    <div className="copyMainTile">
                        <div className="content">
                            <div id="heading-div">
                                <img src={PrintHead} alt="projects: illustration, signange, print work, layout" className="img-fluid" />
                                <h1 className='sr-only'>Graphics Projects</h1>
                                <br />
                                {/* <a href="graphic-slides">Slide View</a> |
                                <a href="graphic-design">List View</a> */}
                            </div>
                            <br /><br />
                            <div className="row projThumbs">
                                <div className='col-7'>
                                    <h1><a href="#">Brochures/ Mailers</a></h1>
                                    <br />
                                    In addition to web based marketing and design, various print work was created for Industrial Access. Brochures and other creatives were designed to be sent out to various power plants to capture possible leads for future inspections, cleanings, and repairs. These high resolution brochures helped to truly illustrate Industrial Access's daring and specialized services.
                                </div>
                                <div className='col-5'>
                                    <img src={SmallBrochure} className="img-fluid" />
                                </div>
                            </div>
                            <div className="row projThumbs">
                                <div className='col-7'>
                                    <h1><a name="land-illustration-anchor" href="print-graphics-proj.php?print=4">Digital Imaging/ Landscape Illustration</a></h1>
                                    <br />
                                    Working in conjuction with a 3D modeling company, I utilized photoshop to illustrate and digitally composite landscapes around 3D Studio Max house renderings.

                                </div>
                                <div className='col-5'>
                                    <img src={IllustrationLandscape} alt="Landscape Illustration" className="img-fluid" />
                                </div>
                            </div>
                            <div className="row projThumbs">
                                <div className='col-7'>
                                    <h1><a href="#">CD Design/Interactive Brochure</a></h1>
                                    <br />
                                    Interactive design projects were complemented by print work on CDs and USB drives. This CD design is for a rich media concept which includes an autoplay Flash Interface highlighting Industrial Access's company services.
                                </div>
                                <div className='col-5'>
                                    <img src={SmCD} alt="Industrial Access" className="img-fluid" />
                                </div>
                            </div>
                            <div className="row projThumbs">
                                <div className='col-7'>

                                    <h1><a name="illustration-anchor" href="print-graphics-proj.php?print=5">Conceptual Illustration</a></h1>
                                    <br />
                                    This project is a digital collage utilizing illustration and composite imagery. Conceptually it is a whimsical parody on the myriad of differing cultural perceptions that occur on this diverse planet.

                                </div>
                                <div className='col-5'>
                                    <img src={EarthAlien} alt="Industrial Access" className="img-fluid" />
                                </div>
                            </div>
                            <div className="row projThumbs">
                                <div className='col-7'>

                                    <h1><a name="tradeshow-anchor" href="print-graphics-proj.php?print=6">Tradeshow Marketing</a><br />
                                    </h1>
                                    Various signs, metal business cards, logo adorned bottles, USB drives, posters and other merchandise were produced to create a presence and brand an identity for Industrial  Access at trade shows. Print design often paralleled electronic media.
                                </div>
                                <div className='col-5'>
                                    <img src={MetalCard} alt="Industrial Access" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <br /><br /><br />
                    </div>
                    <div className="copyBot">
                        <div className="copyBotLC"></div>
                        <div className="copyBotTile"><div className="copyBotRC"></div></div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default GraphicDesign;