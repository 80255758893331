import React, { useState, useEffect } from 'react';
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import AHP_Rendering from '../images/print_med/render-med.jpg';
import IaCdBooklet from '../images/print_med/med-cd-booklet.png';
import IndAccessPrint from '../images/print_med/ind-access-brochure-med.jpg';
import EarthAlien from '../images/print_med/ealien_med.jpg';
import MetalCard from '../images/print_med/metal-card-med.png';
import ModalIaBrochure from './modal-from-scratch/print-modal-ia-brochure.js';
import ModalRendering from './modal-from-scratch/print-modal-landscape.js';
import ModalCd from './modal-from-scratch/print-modal-ia-cd.js';
import ModalAlien from './modal-from-scratch/print-modal-earth-alien.js';
import ModalMetalCard from './modal-from-scratch/print-modal-metal-bizcard.js';

export default function App() {
    const [brochureModalState, setbrochureModalState] = useState(false);
    const [renderingModalState, setrenderingModalState] = useState(false);
    const [cdModalState, setcdModalState] = useState(false);
    const [alienModalState, setalienModalState] = useState(false);
    const [metalCardModalState, setmetalCardModalState] = useState(false);

    // openModalCd
    function openBrochureModal() {
        setbrochureModalState(!brochureModalState)
    }
    function openModalRendering() {
        setrenderingModalState(!renderingModalState)
    }

    function openModalCd() {
        setcdModalState(!cdModalState)
    }

    function openModalAlien() {
        setalienModalState(!alienModalState)
    }

    function openModalMetalCard() {
        setmetalCardModalState(!metalCardModalState)
    }

    return (
        <>
            {/* <ModalFromScratch toggle={modalState} action={openModal} />
             */}
            <MDBCarousel showControls showIndicators fade touch={false}>
                <MDBCarouselItem itemId={1}>
                    {/* <img src='https://mdbootstrap.com/img/new/slides/041.jpg' className='d-block w-100' alt='...' /> */}
                    <div className="row slide">
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                            <a href="#" className="togglemodal" onClick={openBrochureModal}><img src={IndAccessPrint} alt="Industrial Access Marketing and Tradeshows" className="img-fluid"
                            /></a>
                        </div>
                        <div className='col-sm-12  col-md-6 col-lg-6 verbiage'>
                            <h2><a href="#" className="togglemodal" onClick={openBrochureModal}>Industrial Access</a></h2>
                            <p>In addition to web based marketing and design, various print work was created for Industrial Access. Brochures and other creatives were designed to be sent out to various power plants to capture possible leads for future inspections, cleanings, and repairs.</p>
                        </div>
                    </div>
                </MDBCarouselItem>
                <MDBCarouselItem itemId={2}>
                    <div className="row slide">
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                            <a href="#" className="togglemodal" onClick={openModalRendering}>
                                <img src={AHP_Rendering} alt="Second Image" className="img-fluid" />
                            </a>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 verbiage'>
                            <h2><a href="#" className="togglemodal" onClick={openModalRendering}>Digital Imaging/ Landscape Illustration</a></h2>
                            <p>Working in conjuction with a 3D modeling company, I utilized photoshop to illustrate and digitally composite landscapes around 3D Studio Max house renderings, while at America's Home Place.</p>
                        </div>
                    </div>
                </MDBCarouselItem>
                <MDBCarouselItem itemId={3}>
                    {/* <img src='https://mdbootstrap.com/img/new/slides/043.jpg' className='d-block w-100' alt='...' /> */}
                    <div className="row slide">
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                            <a href="#" className="togglemodal" onClick={openModalCd}>
                                <img src={IaCdBooklet} alt="Industrial Access CD and Cover" />
                            </a>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 verbiage'>
                            <h2><a href="#" className="togglemodal" onClick={openModalCd}>Indutrial Access Tradeshows</a></h2>
                            <p>CD Design/Interactive Brochure<br />
                                Interactive design projects were complemented by print work on CDs and USB drives. This CD design is for a rich media concept which includes an autoplay Flash Interface highlighting Industrial Access's company services.</p>
                        </div>
                    </div>
                </MDBCarouselItem>
                <MDBCarouselItem itemId={4}>
                    {/* <img src='https://mdbootstrap.com/img/new/slides/043.jpg' className='d-block w-100' alt='...' /> */}
                    <div className="row slide">
                        <div className='col-sm-12 col-md-6  col-lg-6'>
                            <a href="#" className="togglemodal" onClick={openModalAlien}>
                                <img src={EarthAlien} alt="Conceptual Illustration" />
                            </a>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 verbiage'>
                            <h2><a href="#" className="togglemodal" onClick={openModalAlien}>Conceptual Illustration</a></h2>
                            <p>This project is a digital collage utilizing illustration and composite imagery. Conceptually it is a whimsical parody on the myriad of differing cultural perceptions that occur on this diverse planet.</p>
                        </div>
                    </div>
                </MDBCarouselItem>
                <MDBCarouselItem itemId={5}>
                    {/* <img src='https://mdbootstrap.com/img/new/slides/043.jpg' className='d-block w-100' alt='...' /> */}
                    <div className="row slide">
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                            <a href="#" className="togglemodal" onClick={openModalMetalCard}>
                                <img src={MetalCard} alt="Metal Business Card" />
                            </a>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 verbiage'>
                            <h2><a href="#" className="togglemodal" onClick={openModalMetalCard}>Tradeshow Marketing</a></h2>
                            <p>Various signs, metal business cards, logo adorned bottles, USB drives, posters and other merchandise were produced to create a presence and brand an identity for Industrial Access at trade shows. Print design often paralleled electronic media.</p>
                        </div>
                    </div>
                </MDBCarouselItem>
            </MDBCarousel>
            <ModalIaBrochure toggle={brochureModalState} action={openBrochureModal} />
            <ModalRendering toggle={renderingModalState} action={openModalRendering} />
            <ModalCd toggle={cdModalState} action={openModalCd} />
            <ModalAlien toggle={alienModalState} action={openModalAlien} />
            <ModalMetalCard toggle={metalCardModalState} action={openModalMetalCard} />
        </>
    );
}