import React from 'react'
import './Contact.scss';
import './contact-animation.scss';
import Swal from 'sweetalert2';
import contactBtn from './contact-btn.png';

const ContactForm = () => {
    // WARNING!!!
    // Fill in the ACCESS KEY in the code below
    // You can get an access key on Web3Forms
    // Register by typing your email on Web3Forms
    // Web3Forms link ==> https://web3forms.com/
    // The tutorial on obtaining an access key starts at minute 07:10 to 08:15
    // Thank you

    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        formData.append("access_key", "4b67712e-c6d6-4fc8-ad7a-e559f7bdf205");

        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);

        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: json
        }).then((res) => res.json());

        if (res.success) {
            Swal.fire({
                title: "Success!",
                text: "Message sent successfully!",
                icon: "success"
            });
        }
    };

    return (
        <section className="contact">           
            <form onSubmit={onSubmit}>
                <div className="input-box">
                    <label>Full Name</label>
                    <input type="text" className="field" placeholder='Enter your name' name='name' required />
                </div>
                <div className="input-box">
                    <label>Email Address</label>
                    <input type="email" className="field" placeholder='Enter your email' name='email' required />
                </div>
                <div className="input-box">
                    <label>Your Message</label>
                    <textarea name="message" className="field mess" placeholder='Enter your message' required></textarea>
                </div>
                <button type="submit"><img src={contactBtn} alt="Submit" className="img-fluid" /></button>
            </form>
        </section>
    )
}

export default ContactForm