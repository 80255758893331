import React from "react";
import ReactDom from "react-dom"; 
import "./modalToggle.css";
import ZmodalCloser from "./images/modal-closer.png";
import ZmodalTop from "./images/modal-top.png";
import ZmodalBottom from "./images/modal-bott.png";
import PrintCd from '../../images/print_lg/cd-book-cover.jpg';

export default function PrintCdModal(props) {
  const cdModalState = props.toggle
  const action = props.action

  return ReactDom.createPortal (
    <>
    <div className={`z-container ${cdModalState ? 'active' : ''}`}>
      <div className="z-modal">
        <div className="z-modal-closer">
          <a className="close" onClick={action} href="#">
            <img src={ZmodalCloser} alt="Close modal window" />
          </a>
        </div>
        <div className="z-modal-header">
          <img alt="" src={ZmodalTop} />
        </div>
        <div className="z-modal-body">
          <img className="img-fluid" alt="CD designs- Industrial Access" src={PrintCd} />
        </div>
        <div className="z-modal-footer">
          <img alt="" src={ZmodalBottom} />
        </div>
      </div>
    </div>
    </>,
    document.getElementById('modal-root')
  );
}
