
import home_banner from '../images/flash-Alt.png';
import HomePageAnimation2 from './iframe2.js';
const Banner = () => {
    return (
        <div>
            <div className="top-Z-tile"></div>
            <div className="mid-Z-tile"></div>
            <div className="bot-Z-tile"></div>
            <div>
                <HomePageAnimation2 />
                {/* <img src={home_banner} /> */}
            </div>
        </div>
    )
}

export default Banner;