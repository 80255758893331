import React from "react";
import ReactDom from "react-dom";

import "./modalToggle.css";
import ZmodalCloser from "./images/modal-closer.png";
import ZmodalTop from "./images/modal-top.png";
import ZmodalBottom from "./images/modal-bott.png";
import ResumePage1 from "./images/resume-trip-header.1.png";
import ResumePage2 from "./images/resume-trip-header.2.png";
import ResumePage3 from "./images/resume-trip-header.3.png";

// import WebIaccess from "../../images/web_lg/industrial-access-lg.jpg";

export default function Modal2(props) {
  const modalRoot = document.getElementById('modal-root');
  const modalResumeState = props.toggle
  const action = props.action

  return ReactDom.createPortal(
    <>
      <div className={`z-container ${modalResumeState ? 'active' : ''}`}>
        <div className="z-modal">
          <div className="z-modal-closer">
            <a className="close" onClick={action} href="#">
              <img src={ZmodalCloser} alt="Close modal window" />
            </a>
          </div>
          <div className="z-modal-header">
            <img alt="" src={ZmodalTop} />
          </div>
          <div className="z-modal-body">
            <p className="text-left">To request an electronic copy of my resume with my complete contact information, please use the site's <a href="/contact">contact form</a> and I will get back with you asap.
            </p>
            <p className="text-center scroll-message">
              <strong>[Scrollable]</strong>
            </p>

            <div className="modalScroll">
              <a href="/contact">
                <img className="img-fluid" alt="" src={ResumePage1} />
                <img className="img-fluid" alt="" src={ResumePage2} />
                <img className="img-fluid" alt="" src={ResumePage3} />
              </a>
            </div>
          </div>
          <div className="z-modal-footer">
            <img alt="" src={ZmodalBottom} />
          </div>
        </div>
      </div>
    </>,
    document.getElementById('modal-root')
  );
}
