
import React, { useState, useEffect } from 'react';
import logo2 from '../images/logo2.png';
import MdBsNav from './md-bootstrap-nav.js';
import LetterIcon from '../images/letter-icon.png';
import DocIcon from '../images/doc-icon.png';
import ModalResume from '../components/modal-from-scratch/resume-modal.js';

const Header = () => {
    const [modalResumeState, setModalResumeState] = useState(false);
    function openResumeModal() {
        setModalResumeState(!modalResumeState)
    }

    return (
        <>
        <div className="header">
            <div className="headerLeft"></div>
            <div className="headerMain">
                <span className="contact-link">
                    <a href="#" className="togglemodal" onClick={openResumeModal}>
                        <img src={DocIcon} alt="Resume" />
                        Resume
                    </a>
                    <a  href="contact">
                        <img src={LetterIcon} alt="Contact Me" />
                        Contact
                    </a>
                </span>
                <div className="headerLogo"><a href="/">
                    <img src={logo2} alt="BCrowe.net" /></a>
                </div>
                <div id="headerNav">
                    <MdBsNav />
                </div>
            </div>
            <div className="headerRight"></div>
        </div>
        <ModalResume toggle={modalResumeState} action={openResumeModal} />
        </>
    )
}

export default Header;