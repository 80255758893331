
import Experiencebanner from '../images/Experiencebanner.png';
import InterfaceHeading from '../images/about-interface-head.png';
import Carousel from '../components/md-bootstrap-carousel';
import { Helmet } from 'react-helmet-async';

const InterfaceSlide = () => {
    return (
        <div className="sub interface-slides">
            <Helmet>
                <title>Interface Design Slides- B.Crowe Design</title>
                <meta name="Interface Design Slides" content="Interface Design Slideshow" />
            </Helmet>
            <div className="top-Z-Proj-tile"></div>
            <div className="mid-Z-Proj-tileWeb"></div>
            <div className="bot-Z-Proj-tile"></div>
            <div id="mainWrapper">
                <div id="projHolder">
                    <div id="loopedSlider">
                        <div class="slides">
                            <Carousel />
                        </div>
                    </div>
                </div>
                <div id="copyFrame" class="sub">
                    <div className="copyTop">
                        <div className="subcopyTopLC"></div>
                        <div className="subcopyTopTile">
                            <div className="subcopyTopRC"></div>
                        </div>
                    </div>
                    <div className="copyMainTile">
                        <div class="content">
                            <div id="heading-div"><img src={InterfaceHeading} alt="Information about skills and services offered for web design, interface development, etc." className="img-fluid" />
                                <br />
                                <h1 className="sr-only">Interface Design Projects Slideshow</h1>
                            </div>
                            <div class="blurbage">Interactive design services include web design, branding identity, search
                                engine optimization, web marketing, lead capture, rich media development, interface design for
                                internal systems and much more. Design and development of graphics, CSS, and rich media are
                                among my strengths. I have experience in developing interfaces that are intagrated into various
                                frameworks; PHP, asp.Net, etc.
                            </div>
                            <div className='serviceBullets'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <ul>
                                            <li>Interface Design</li>
                                            <li>Front-End Development</li>
                                            <li>Web Marketing</li>
                                        </ul>
                                    </div>
                                    <div className='col-6'>
                                        <ul>
                                            <li>SEO Landing Pages</li>
                                            <li>Interactive Media Design</li>
                                            <li>Information Architecture</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyBot">
                        <div className="copyBotLC"></div>
                        <div className="copyBotTile">
                            <div className="copyBotRC"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InterfaceSlide;