
import Linkedin from '../images/linkedin.png';


const Footer = () => {
   

    return (
        <>
            <div className='disclaimer-container'>
                <a href="http://www.linkedin.com/in/brendan-crowe-379063136" target="_blank">
                    <img src={Linkedin} alt="Linkedin" />
                </a>
                <p className='disclaimer'>Disclaimer: Most of the projects displayed on this site are the property of the respective companies listed. These websites, marketing and branding are all subject to change or may have already been changed in production and in the field at the respective company's peragative.</p>
            </div>
        </>
    )
}

export default Footer;