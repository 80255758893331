import React, { useState } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from 'mdb-react-ui-kit';
import mob_slide_logo from '../images/mobile-menu/images/mobile-slideout-logo.png';

export default function App() {
  const [openBasic, setOpenBasic] = useState(false);
  
  return (
    <div>
      <MDBNavbar expand='md'>
        <MDBContainer fluid>
          {/* <MDBNavbarBrand href='#'>Brand</MDBNavbarBrand> */}
          <MDBNavbarToggler
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
            onClick={() => setOpenBasic(!openBasic)}
          >
            <MDBIcon
              icon='bars' fas
              style={{ color: 'White' }}
          />
          
          </MDBNavbarToggler>
          {/* <li id="homey" className="leftNavBtn"><a href="/">Home</a></li>
                      <li id="mNavBtn" className="nav-tile"><a href="/interface-design"
                      >Interface Design</a></li>
                      <li id="mNavBtn2" className="nav-tile2"><a href="/graphic-design"
                      >Graphics</a></li>
                      <li id="exp" className="rightNavBtn"><a href="/experience">Experience</a></li> */}
          <MDBCollapse navbar open={openBasic}>
            <MDBNavbarNav>
              <MDBNavbarItem className = {window.location.pathname === '/' ? "leftNavBtn active" : "leftNavBtn" }>
                <MDBNavbarLink href='/'>
                  Home
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem className = {window.location.pathname === "/interface-slides" ? "nav-tile active" : "nav-tile" }>
                <MDBNavbarLink  href='/interface-slides'>
                   Interface Design
                </MDBNavbarLink>
                {/* <MDBDropdown>
                  <MDBDropdownToggle link tag='a' className='nav-link' role='button'>
                    Interface Design
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem link  href='/interface-design'>Interface List</MDBDropdownItem>
                    <MDBDropdownItem link  href='/interface-slides'>Interface Slides</MDBDropdownItem>
                 </MDBDropdownMenu>
                </MDBDropdown> */}
              </MDBNavbarItem>
              <MDBNavbarItem className = {window.location.pathname === "/graphic-slides" ? "nav-tile2 active" : "nav-tile2" }>
                  <MDBNavbarLink href='/graphic-slides'>
                      Graphic Design
                  </MDBNavbarLink>
                  {/* <MDBDropdown>
                  <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                    Graphic Design
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem link href='/graphic-design'>Graphics List</MDBDropdownItem>
                    <MDBDropdownItem link href='/graphic-slides'>Graphics Slides</MDBDropdownItem>
                 </MDBDropdownMenu>
                </MDBDropdown> */}
              </MDBNavbarItem>
              <MDBNavbarItem className={window.location.pathname === '/experience' ? "nav-tile2 active" : "nav-tile2" }>
                <MDBNavbarLink  href='/experience'>Experience</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem className = {window.location.pathname === '/contact' ? "nav-tile active d-lg-none d-md-none" : "nav-tile d-lg-none d-md-none" }>
                <MDBNavbarLink href='/contact'> 
                  Contact
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem className="bottom-mob-nav-logo d-lg-none d-md-none">
                <img className="mob-slide-logo d-lg-none" src={mob_slide_logo} />
              </MDBNavbarItem>
                {/* <MDBDropdown>
                  <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                    Dropdown
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem link>Action</MDBDropdownItem>
                    <MDBDropdownItem link>Another action</MDBDropdownItem>
                    <MDBDropdownItem link>Something else here</MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem> */}
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </div>
  );
}