import React, { useState, useEffect } from 'react';
import InterfaceBanner from '../images/interfaceBanner.png';
import MobileInterfaceBanner from '../images/mobile-interface-banner.png';
import InterfaceHeading from '../images/proj-heading.png';
import IndAccessThumb from '../images/web_thumbs/webthumb_ia.jpg';
import AHPThumb from '../images/web_thumbs/ahp-land-thumb.jpg';
import CSIThumb from '../images/web_thumbs/webthumb_csi.jpg';
import WaltThumb from '../images/web_thumbs/webthumb_walts.jpg';
import TradeShowThumb from '../images/web_thumbs/rich-media-tradeshow.jpg';
import AHPPubThumb from '../images/web_thumbs/webthumb_ahp_pub.jpg';
import CaterersThumb from '../images/web_thumbs/webthumb_caterers.jpg';
import AHPInetThumb from '../images/web_thumbs/webthumb_ahp_inet.jpg';
import AHPHouseThumb from '../images/web_thumbs/webthumb_ahp_housing.jpg'
import AHPInet from '../images/web_thumbs/webthumb_ahp_inet.jpg';

import VarModal from '../components/var-modal.tsx';
import SCBZ_PIR from '../images/web_thumbs/webthumb_scbz_pir.jpg';
import ENS_SC from '../images/web_thumbs/webthumb_sc_ens.jpg';
import { MDBBtn } from 'mdb-react-ui-kit';
import { Helmet } from 'react-helmet-async';
import ModalFromScratch from '../components/modal-from-scratch/web-modal-ens.js';
import '../components/modal-from-scratch/modalToggle.css';

// MODALS to add
//import ModalFromScratch from './modal-from-scratch/web-modal-ens.js';
// import ModalFromScratch2 from './modal-from-scratch/modal2.js';
// import ModalFromScratch3 from './modal-from-scratch/modal3.js';
// import WebModalPir from './modal-from-scratch/web-modal-pir.js';
// import WebModaITrade from './modal-from-scratch/web-modal-IA-tradeshow.js';
// import WebModalWalts from './modal-from-scratch/web-modal-walts.js';


const InterfaceDesign = () => {
    // const [basicModal, setBasicModal] = useState(false);
    // const toggleOpen = () => setBasicModal(!basicModal);

    const [modalState, setModalState] = useState(false);

    function openModal () {
        setModalState(!modalState)
    }
    return (
        <div className="sub">
            <Helmet>
                <title>Interface Design Listings- B.Crowe Design</title>
                <meta name="Interface Design Listings" content="Interface Design Listings" />
            </Helmet>
            <div class="top-Z-Sub-tile"></div>
            <div class="mid-Z-Sub-tile"></div>
            <div class="bot-Z-Sub-tile"></div>
            <div class="bannerHolder interface">
                {/* <img src={InterfaceBanner} alt="interface design" width="1520" height="84" /> */}
                <img className="d-md-none d-lg-none img-fluid" src={MobileInterfaceBanner} />
            </div>
            <div id="mainWrapper">
                <div id="copyFrame" class="sub">
                    <div className="copyTop">
                        <div className="subcopyTopLC"></div>
                        <div className="subcopyTopTile">
                            <div className="subcopyTopRC"></div>
                        </div>
                    </div>
                    <div className="copyMainTile">
                        <div className="content">
                            <div id="heading-div">
                                <img className="img-fluid" src={InterfaceHeading} alt="Contact web graphics designer" /><br />
                                <h1 className="sr-only">Interface Design Projects</h1>
                                {/* <a href="interface-slides">Slide View</a> |
                                <a href="interface-design">List View</a> */}

                                <button className="togglemodal" onClick={openModal}>OPEN</button>
                                <ModalFromScratch toggle={modalState} action={openModal} /> 
                            </div>
                            {/* SCBZ_PIR */}
                            <div className="row projThumbs">
                                <div className="col-7">
                                    <h1>SecondChance Bonus Zone</h1>
                                    <p></p>
                                </div>
                                <div className="col-5">
                                    <img className="img-fluid" src={SCBZ_PIR} alt="SCBZ Price is Right" />
                                </div>
                            </div>
                            <div className="row projThumbs">
                                <div className="col-7">
                                    <h1>SC Ensemble</h1>
                                    <p>Involvement in Frontend Development effort for the South Carolina Lottery while working with Scientific Games </p>
                                </div>
                                <div className="col-5">
                                    <img className="img-fluid" src={ENS_SC} alt="Ensemble Platform for the South Carolina Lottery" />
                                </div>
                            </div>
                            <div className="row projThumbs">
                                <div className="col-7">
                                    <h1>IndustrialAccess.com</h1>
                                    <p>Industrial Access is the industrial smokestack and steeplejack branch of Chimney Solutions Incorporated. The branding for this new division hinged on the sheer danger and industrial nature of rope access, smokestack cleaning, rigging & lifting, demolition and more.</p>
                                </div>
                                <div className="col-5">
                                    <img className="img-fluid" src={IndAccessThumb} alt="Industrial Access" />
                                </div>
                            </div>

                            <div className="row projThumbs">
                                <div className="col-7">
                                    <h1><a href="web-interface-proj.php">AHP Web Marketing</a></h1>
                                    <br />
                                    <p>This project involved creating the front-end for   pay-per-click landing pages for America's Home Place. Through this system, campaigns were created which directly silo a prospective client into their specific area of interest. This form of web marketing is a great source of lead generation which bypasses the dilluted information that is found  in large company web sites.</p>
                                </div>
                                <div className="col-5">
                                    <a name="web-mark-anchor" href="web-interface-proj.php?website=3">
                                        <img className="img-fluid" src={AHPThumb} alt="Industrial Access" /></a>
                                </div>
                            </div>
                            <div className="row projThumbs">
                                <div className="col-7">
                                    <h1><a href="web-interface-proj.php?website=6">Walt's Mobile Bistro</a></h1>
                                    <br />
                                    The goal of this project was to create a brochure web site for a new mobile restaurant concept in Denver, Co. It was designed  to complement their print campaign and to illustrate their service in a colorful 'mobile' way.
                                </div>
                                <div className="col-5">
                                    <a name="walt-anchor" href="web-interface-proj.php?website=4"><img src={WaltThumb} className='img-fluid' alt="" /></a>
                                </div>
                            </div>
                            <div className="row projThumbs">
                                <div className="col-7">
                                    <h1><a href="web-interface-proj.php?website=4">Chimney Solutions</a></h1><br />
                                    ChimneySolutions.com serves as a one of the company's main sources of adertising and branding identity. Employed in the redesign for this site was a thorough analysis of information architecture to deliver a truly informative and user friendly experience.
                                </div>

                                <div className="col-5">
                                    <a href="web-interface-proj.php?website=4"><img src={CSIThumb} alt="Chimney Solutions" className="img-fluid" /></a>
                                </div>
                            </div>
                            <div className="row projThumbs">
                                <div className="col-7">
                                    <h1><a href="print-graphics-proj.php?print=3">I.A. Interactive Brochure</a></h1>
                                    <br />
                                    This interactive Flash brochure was developed for Industrial Access. Interactive rich media created for use at tradeshows.  Promotional interactive projects such as this were created to further engage potential leads at trade shows with the dangerous and specialized field of industrial rope access.
                                </div>
                                <div className='col-5'>
                                    <a name="interactive-anchor" href="web-interface-proj.php?website=5"><img src={TradeShowThumb} alt="Industrial Access" className="img-fluid" /></a>
                                </div>
                            </div>
                            <div className="row projThumbs">
                                <div className='col-7'>
                                    <h1><a href="web-interface-proj.php?website=5">AmericasHomePlace.com</a></h1>
                                    <br />
                                    <p><strong>AmericasHomePlace.com</strong> is the company's flagship web site. Redesign of  this site involved the creation of a simple and clean layout with full integration of company data base and CMS.</p>
                                </div>
                                <div className='col-5'>
                                    <a name="ahp-com-anchor" href="web-interface-proj.php?website=6"><img src={AHPPubThumb} alt="" className="img-fluid" /></a>
                                </div>
                            </div>
                            <div className="row projThumbs" >
                                <div className='col-7'>
                                    <h1><a href="web-interface-proj.php">America's Home Place</a></h1>
                                    <br />
                                    <p>The America's Home Place public web site  is the company's main source of lead generation Development of  this site involved a striking a  balance between the company's changing marketing strategy and with the  internal data systems. In addition to the site's main profile, there are profiles representing each and every office. These respective offices could control their via the internal intranet website control. See details on the AHP internal intranet interface.</p>
                                </div>
                                <div className='col-5'>
                                    <a href="web-interface-proj.php"><img src={AHPThumb} alt="" className="img-fluid" /></a>
                                </div>
                            </div>
                            <div className="row projThumbs">
                                <div className='col-7'>
                                    <h1><a href="web-interface-proj.php?website=8">AHP Housing Media</a></h1>
                                    <br />
                                    <p>Serving as a design liason between America's Home Place marketing department and the I.T. department, I designed the front-end of this site that was developed as an internal markeing tool for the various field offices to place orders for marketing/graphical materials. Featured here is the public front-end which serves as a welcome center to new visitors. This is also used in the ongoing SEO strategy.</p>
                                </div>
                                <div className='col-5'>
                                    <a name="housing-anchor" href="web-interface-proj.php?website=7"><img src={AHPHouseThumb} alt="AHP Housing Media" className="img-fluid" /></a>
                                </div>
                            </div>
                            <div className="row projThumbs">
                                <div className='col-7'>
                                    <h1><a href="web-interface-proj.php?website=10">AHP Intranet</a></h1>
                                    <br />
                                    <p>Design for America's Home Place employee intranet involved keeping synergy with the company's public image whilealso promoting a prowess of technology and efficiency for the entire company.</p>
                                </div>
                                <div className='col-5'>
                                    <a href="web-interface-proj.php?website=8"><img src={AHPInetThumb} alt="AHP Intranet" className="img-fluid" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyBot">
                        <div className="copyBotLC"></div>
                        <div className="copyBotTile"><div className="copyBotRC"></div></div>
                    </div>
                </div>
            </div>
            {/* <VarModal /> */}
            {/* <Modal /> */}
        </div>
    )
}

export default InterfaceDesign;