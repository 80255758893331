import React from "react";
import ReactDom from "react-dom";
import "./modalToggle.css";
import ZmodalCloser from "./images/modal-closer.png";
import ZmodalTop from "./images/modal-top.png";
import ZmodalBottom from "./images/modal-bott.png"
import WebIaTradeImg from "../../images/web_lg/tradeshow-IA-Flash.jpg";

export default function WebIaTrade(props) {
  // const modalRoot = document.getElementById('modal-root');
  const webModalIaTradeState = props.toggle
  const action = props.action

  return ReactDom.createPortal(
    <>
      <div className={`z-container ${webModalIaTradeState ? 'active' : ''}`}>
        <div className="z-modal">
          <div className="z-modal-closer">
            <a className="close" onClick={action} href="#">
              <img src={ZmodalCloser} alt="Close modal window" />
            </a>
          </div>
          <div className="z-modal-header">
            <img alt="" src={ZmodalTop} />
          </div>
          <div className="z-modal-body">
            <img className="img-fluid" alt="" src={WebIaTradeImg} />
          </div>
          <div className="z-modal-footer">
            <img alt="" src={ZmodalBottom} />
          </div>
        </div>
      </div>
    </>,
    document.getElementById('modal-root')
  );
}
