import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
// test ReactDOM
// import ReactDOM from "react-dom/client";
import './App.css';
import './css/mainStyle.scss';
import Header from './components/header';
import Footer from './components/footer';
import Home from './pages/home';
import InterfaceDesign from './pages/interfaceDesign';
import InterfaceSlide from './pages/interfaceSlide';
import GraphicSlide from './pages/graphicSlide';
import GraphicDesign from './pages/graphicDesign';
import Experience from './pages/experience';
import Contact from './pages/contact';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import ModalManager from  './components/modal-general/ModalManager.jsx';
// import ResponsiveModal from './components/responsive-modals/index.js';
// import ModalFromScratch from './components/modal-from-scratch/modal.js';
// import './components/modal-from-scratch/modalToggle.css';

function App() {
  const [modalState, setModalState] = useState(false)
  const Routz = <BrowserRouter>
    <Routes>
      <Route path="/" element={ <Home/> } />
      <Route path="interface-design" element={ <InterfaceDesign/> } />
      <Route path="interface-slides" element={ <InterfaceSlide/> } />
      <Route path="graphic-design" element={ <GraphicDesign/> } />
      <Route path="graphic-slides" element={ <GraphicSlide /> } />
      <Route path="experience" element={ <Experience /> } />
      <Route path="contact" element={ <Contact /> } />
    </Routes>
  </BrowserRouter>;

  return (
    <div className="App">
      <div className='da-container'>
        <Header />
        {Routz}
        <Footer />
      </div>
    </div>
  );
}

export default App;
