
import MobileContactBanner from '../images/MobileContactBanner.png';
import ContactForm from '../components/Contact/ContactForm.jsx';
import ContactHeading from '../images/contact-heading.png';
import { Helmet } from 'react-helmet-async';
const Contact = () => {
    return (
        <div className="sub">
            <Helmet>
                <title>Contact Form- B.Crowe Design</title>
                <meta name="BCrowe.net- Contact Form" content="BCrowe.net- Contact Form" />
            </Helmet>
            <div className="top-Z-Sub-tile"></div>
            <div className="mid-Z-Sub-tile">
                <div id="background-wrap">
                    <div class="x1">
                        <div class="mail"></div>
                    </div>
                    <div class="x2">
                        <div class="mail"></div>
                    </div>
                    <div class="x3">
                        <div class="mail"></div>
                    </div>
                    <div class="x4">
                        <div class="mail"></div>
                    </div>
                    <div class="x5">
                        <div class="cloud"></div>
                    </div>
                </div>
            </div>
            <div className="bot-Z-Sub-tile"></div>
            <div className="bannerHolder contact">
                <img src={MobileContactBanner} alt="Contact Us" className="d-md-none d-lg-none img-fluid" />
            </div>
            <div id="mainWrapper">
                <div id="copyFrame" className="sub">
                    <div className="copyTop">
                        <div className="subcopyTopLC"></div>
                        <div className="subcopyTopTile">
                            <div className="subcopyTopRC"></div>
                        </div>
                    </div>
                    <div className="copyMainTile">
                        <div className="content">
                            <div id="heading-div">
                                <img className="img-fluid" src={ContactHeading} alt="Contact Form" />
                                <h1 className='sr-only'>Contact Form</h1>  
                            </div>
                            <p className="blurbage">Please fill out the following form regarding resume requests, service inquiries, or general feedback and I will get back to you at my next opportunity. Thank You.</p>              
                            <ContactForm />
                        </div>
                    </div>
                    <div className="copyBot">
                        <div className="copyBotLC"></div>
                        <div className="copyBotTile">
                            <div className="copyBotRC"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;