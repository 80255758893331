import React, { useState, useEffect } from 'react';
import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
import WebAI from '../images/web_med/web_med_ia.jpg';
import ENS_SC from '../images/web_med/web-ens-sc.jpg';
import ChimSite from '../images/web_med/web_chim.jpg';
import SCBZ_PIR from '../images/web_med/PIR-desktop.jpg';
import IATradeShow from '../images/web_med/tradeshow-IA-Flash.png';
import Walts from '../images/web_med/web_walt_med.jpg';
// import CSIThumb from '../images/web_thumbs/webthumb_csi.jpg';
// import WaltThumb from '../images/web_thumbs/webthumb_walts.jpg';
// import AHPPubThumb from '../images/web_thumbs/webthumb_ahp_pub.jpg';
// import CaterersThumb from '../images/web_thumbs/webthumb_caterers.jpg';
// import AHPInetThumb from '../images/web_thumbs/webthumb_ahp_inet.jpg';
// import AHPHouseThumb from '../images/web_thumbs/webthumb_ahp_housing.jpg'
// import AHPInet from '../images/web_thumbs/webthumb_ahp_inet.jpg';
// import Walt from '../images/web_med/web_walt_med.jpg';

import ModalFromScratch from './modal-from-scratch/web-modal-ens.js';
import ModalFromScratch2 from './modal-from-scratch/modal2.js';
import ModalFromScratch3 from './modal-from-scratch/modal3.js';
import WebModalPir from './modal-from-scratch/web-modal-pir.js';
import WebModaITrade from './modal-from-scratch/web-modal-IA-tradeshow.js';
import WebModalWalts from './modal-from-scratch/web-modal-walts.js';
import './modal-from-scratch/modalToggle.css';

export default function App() {
    const [modalState, setModalState] = useState(false);
    const [modalState2, setModalState2] = useState(false);
    const [modalState3, setModalState3] = useState(false);
    const [webModalPirState, setwebModalPirState] = useState(false);
    const [webModalIaTradeState, setwebModalIaTradeState] = useState(false);
    const [webModalWaltsState, setwebModalWaltsState] = useState(false);

    function openModal() {
        setModalState(!modalState)
    }
    function openModal2() {
        setModalState2(!modalState2)
    }
    function openModal3() {
        setModalState3(!modalState3)
    }
    function openModalPIR() {
        setwebModalPirState(!webModalPirState)
    }
    function openModalIaTrade() {
        setwebModalIaTradeState(!webModalIaTradeState)
    }
    function openModalWalts() {
        setwebModalWaltsState(!webModalWaltsState)
    }

    const modalRoot = document.getElementById('modal-root');

    return (
        <>
            <MDBCarousel showControls showIndicators fade touch={false}>

                <MDBCarouselItem itemId={1}>
                    <div className="row slide">
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                            <a href="#" className="togglemodal" onClick={openModal2}>
                                <img src={WebAI} alt="First Image" className="img-fluid"
                                />
                            </a>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 verbiage'>
                            <div className='verbiage-overflow'>
                                <h2><a href="#" className="togglemodal" onClick={openModal2}>IndustrialAccess.com</a></h2>
                                <p>IndustrialAccess.com represents the industrial smokestack and steeplejack branch of Chimney Solutions
                                    Incorporated. The branding for this new division hinged on the sheer danger and specialized industrial skills of the trade.
                                </p>
                                <h3>Tools &amp; Languages Used</h3>
                                <p>Illustrator, Photoshop, Flash, CSS, Javascript, PHP</p>
                            </div>
                        </div>
                    </div>
                </MDBCarouselItem>
                <MDBCarouselItem itemId={2}>
                    <div className="row slide">
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                            <a href="#" className="togglemodal" onClick={openModal}>
                                <img src={ENS_SC} alt="Ensemble for the South Carolina Lottery. A product platform created by Scientific Games" className="img-fluid"
                                />
                            </a>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 verbiage'>
                            <div className='verbiage-overflow'>
                                <h2><a href="#" className="togglemodal" onClick={openModal}>Scientific Games- Ensemble</a></h2>
                                <p>While employed with Scientific Games I contributed to Ensemble product platform.  It is a react.js based product developed for various state lotteries to provide the online gaming experience. On display is the South Carolina Lottery iteration of the product.
                                </p>
                                <h3>Tools &amp; Languages Used</h3>
                                <p>React.js, SCSS</p>
                            </div>
                        </div>
                    </div>
                </MDBCarouselItem>
                <MDBCarouselItem itemId={3}>
                    <div className="row slide">
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                            <a href="#" className="togglemodal" onClick={openModal3}>
                                <img src={ChimSite} alt="Chimney Solutions" className="img-fluid" />
                            </a>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 verbiage'>
                            <div className='verbiage-overflow'>
                                <h2><a href="#" className="togglemodal" onClick={openModal3}>Chimney Solutions</a></h2>
                                <p>Design and development of public website for Chimney Solutions.com. This paralled print and other media work for the Alpharetta based chimney sweep company.
                                </p>
                                <h3>Tools &amp; Languages Used</h3>
                                <p>Illustrator, Photoshop, CSS, Javascript, JQuery, PHP</p>
                            </div>
                        </div>
                    </div>
                </MDBCarouselItem>
                <MDBCarouselItem itemId={4}>
                    <div className="row slide">
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                            <a href="#" className="togglemodal" onClick={openModalPIR}>
                                <img src={SCBZ_PIR} alt="Scientific Games SCBZ, The Price is Right" className="img-fluid" />
                            </a>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 verbiage'>
                            <div className='verbiage-overflow'>
                                <h2><a href="#" className="togglemodal" onClick={openModalPIR}>Scientific Games-<br />
                                    SCBZ, The Price is Right</a></h2>
                                <p>Developed while employed at Scientific Games, SCBZ was a lottery based product platform that featured visual branding based microsites that would reflect the branding design of various scratch-off tickets for many state lotteries.
                                </p>
                                <h3>Tools &amp; Languages Used</h3>
                                <p>React.js, Adobe XD, SCSS, Javascript</p>
                            </div>
                        </div>
                    </div>
                </MDBCarouselItem>
                <MDBCarouselItem itemId={5}>
                    <div className="row slide">
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                            <a href="#" className="togglemodal" onClick={openModalIaTrade}>
                                <img src={IATradeShow} alt="Industrial Access Tradeshow Projects" className="img-fluid" />
                            </a>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 verbiage'>
                            <div className='verbiage-overflow'>
                                <h2><a href="#" className="togglemodal" onClick={openModalIaTrade}>Industrial Access Tradeshow Projects</a></h2>
                                <p>Various interactive projects were created for tradeshow displays in additon to printwork. 
                                </p>
                                <h3>Tools &amp; Languages Used</h3>
                                <p>Flash, PHP, Illustrator, Photoshop</p>
                            </div>
                        </div>
                    </div>
                </MDBCarouselItem>

                <MDBCarouselItem itemId={6}>
                    <div className="row slide">
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                            <a href="#" className="togglemodal" onClick={openModalWalts}>
                                <img src={Walts} alt="Walt's Bistro" className="img-fluid" />
                            </a>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 verbiage'>
                            <div className='verbiage-overflow'>
                                <h2><a href="#" className="togglemodal" onClick={openModalWalts}>Walt's Bistro</a></h2>
                                <p>Web design and development for a Denver based food truck concept.
                                </p>
                                <h3>Tools &amp; Languages Used</h3>
                                <p>Flash alongside PHP, Illustrator, Photoshop, Jquery, HTML5.</p>
                            </div>
                        </div>
                    </div>
                </MDBCarouselItem>
            </MDBCarousel>
            <ModalFromScratch toggle={modalState} action={openModal} />
            <ModalFromScratch2 toggle={modalState2} action={openModal2} />
            <ModalFromScratch3 toggle={modalState3} action={openModal3} />
            <WebModalPir toggle={webModalPirState} action={openModalPIR} />
            <WebModaITrade toggle={webModalIaTradeState} action={openModalIaTrade} />
            <WebModalWalts toggle={webModalWaltsState} action={openModalWalts} />
        </>
    );
}