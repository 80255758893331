import PrintMedia from '../images/printMediaHomeBtn.png';
import InteractiveHome from '../images/interactiveHomeBtn.png';
import MarketingHome from '../images/webMktgHomeBtn.png';
import Banner from '../components/banner';
import { Helmet } from 'react-helmet-async';

const Home = () => {
    return (
        <div>
            <Helmet>
                <title>B.Crowe Design Home Page</title>
                <meta name="BCrowe.net Landing Page" content="BCrowe.net Overview" />
            </Helmet>
            <Banner />
            <div className="home">
                <div id="copyFrame">
                    <div className="copyTop">
                        <div className="copyTopLC"></div>
                        <div className="copyTopTile">
                            <div className="copyTopRC"></div>
                        </div>
                    </div>
                    <div className="copyMainTile">
                        <div className="homeBtns3">
                            <div className='col-4'>
                                <a href="/graphic-slides"><img className="img-fluid" src={PrintMedia} /></a>
                            </div>
                            <div className='col-4'>
                                <a href="/interface-slides"><img className="img-fluid" src={InteractiveHome} /></a>
                            </div>
                            <div className='col-4'>
                                <a href="/interface-slides"><img className="img-fluid" src={MarketingHome} /></a>
                            </div>
                        </div>
                        <div className="content">
                            <div id="knuckles">
                                <p>
                                    As a designer I strive to develop quality interfaces that are intuitive, aesthetically pleasing and
                                    structurally sound. Through my diverse experience of working in graphics, web design, web marketing and front-end development, I have developed a keen
                                    understanding of the proper work-flow, from design to development. I am for all intents and purposes, the
                                    quintessential "developer's designer". Very important to me is creating a user centered experience that
                                    accentuates visual branding integrity while maintaining accesiblity and a structurally sound code base.
                                </p>
                                <p>
                                    My services and skills encompass <strong>graphics</strong>, <strong>branding</strong>, <strong>interface
                                    design</strong>, <strong>front-end development</strong>, <strong>SEO</strong>, <strong>PPC
                                    marketing</strong>, <strong>interactive media</strong>, and <strong>web based marketing</strong>.
                                    As technology evolves I continue to grow and progress with everchanging trends, both technically and
                                    aesthetically.
                                </p>
                            </div>
                        </div>
                        <div className="copyBot">
                            <div className="copyBotLC"></div>
                            <div className="copyBotTile">
                                <div className="copyBotRC"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;